import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { ReactNode, Suspense, useRef, useState } from "react";
import EditSingleStyles from "../../../styles/editSingleActivity";
import "./create-edit-single-activity.scss";

interface IPreviewModalProps {
  activityData: IActivity;
  validateForm: () => string[];
  errorAlert: (message: string) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<ReactNode>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let MainActivityContainer: React.LazyExoticComponent<
  React.FC<IActivityMainContainer>
> | null = null;

const LoadPreview = (isLoaded: boolean, activityData: IActivity) => {
  if (isLoaded) {
    if (!MainActivityContainer) {
      MainActivityContainer = React.lazy(
        () => import("../view-single-activity/main-activity-container"),
      );
    }
    return (
      <Suspense>
        <Container sx={EditSingleStyles.previewActivityMargin}>
          <div>
            <MainActivityContainer
              activityData={activityData}
              isPreview
              saveActivity={null}
            />
          </div>
        </Container>
      </Suspense>
    );
  }

  return <span />;
};

function PreviewModal({
  activityData,
  validateForm,
  errorAlert,
}: IPreviewModalProps) {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const loadActivityPreview = useRef<boolean>(false);

  const handlePreviewModal = (res: boolean) => {
    if (!res) {
      setIsPreviewModalOpen(res);
      return;
    }

    const hasErrors = validateForm();

    if (hasErrors.length > 0) {
      errorAlert("Please fill the required fields to see preview");
    } else {
      setIsPreviewModalOpen(res);
      loadActivityPreview.current = true;
    }
  };

  return (
    <>
      <Button
        onClick={() => handlePreviewModal(true)}
        color="primary"
        variant="outlined"
      >
        Preview
      </Button>

      <Dialog
        fullScreen
        open={isPreviewModalOpen}
        onClose={() => handlePreviewModal(false)}
        TransitionComponent={Transition}
        keepMounted
        className="bg-pattern"
        PaperProps={{
          className: "custom-scrollbar",
        }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handlePreviewModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {isPreviewModalOpen &&
          LoadPreview(loadActivityPreview.current, activityData)}
      </Dialog>
    </>
  );
}

export default PreviewModal;
