const EditSingleStyles = {
  boxContainer: {
    padding: { sx: 0, md: 5 },
  },
  typoLabel: { mb: 1 },
  gridAlign: { textAlign: "left" },
  checkboxLabelMargin: { m: 1 },
  labelBelowMargin: { mb: 2 },
  checkBoxStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ml: 0,
  },
  checkboxColumnStyle: {
    display: "flex",
    flexDirection: "column",
    ml: 0,
  },
  previewDiagramsStyle: {
    height: "fit-content",
    position: "relative",
    border: "1px solid #ccc",
    p: 2,
    borderRadius: "10px",
    gap: "auto",
  },
  previewUploadStyle: {
    height: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    bgcolor: "#1976d2",
  },
  diagramModalBoxStyles: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    height: "90vh",
    bgcolor: "background.paper",
    boxShadow:
      "0px -48px 100px 0px rgba(17, 12, 46, 0.15), 0px 48px 100px 0px rgba(17, 12, 46, 0.15)",
    p: "20px",
    pt: "10px",
    borderRadius: "5px",
  },
  digramModalTitleWithCrossStyles: {
    mb: 2,
    pb: 1,
    borderBottom: "1px solid #ccc",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  filePreviewsModal: {
    height: "90%",
    width: "100%",
    overflowY: "auto",
  },
  diagramViewStyle: {
    maxHeight: "200px",
    position: "relative",
    p: 1,
  },
  bgcolor: "red",
  wholePaperPadding: { padding: 2 },
  mainTitleCancelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    my: 3,
    px: { xs: 3, md: 0 },
  },
  firstGrid: { p: { xs: "0px", md: "15px" } },
  checkboxLabelStyle: { ml: 0 },
  videoTextFieldStyle: { mb: 1 },
  previewGridStyles: {
    height: "120px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  previewGridInlineStyle: { marginBottom: 0 },
  previewDeleteIconStyle: { fontSize: "14px" },
  uploadCloudIconStyle: { color: "white", fontSize: "28px" },
  previewAndUploadTextStyle: {
    fontSize: { xs: "0.8rem", sm: "1rem" },
    width: "70%",
    color: "white",
    textAlign: "center",
  },
  equipmentCheckboxList: {
    fontSize: "12px ",
  },
  equipmentCheckboxListCheckBox: { marginRight: 8 },
  dialogBoxHeight: { height: "120px" },
  diagramModalCloseIconStyle: { color: "black" },
  renderDiagramStyle: {
    height: "fit-content",
    mt: 1,
  },
  diagramModalActivityTitleStyle: { my: 2 },
  renderDiagramDeleteIconStyle: { fontSize: "20px" },
  singleFileUploadContainer: {
    height: "fit-content",
    width: "100%",
  },
  singleFileUploadContainerInlineStyle: { paddingInline: 0 },
  uploadedDiagramMainContainerStyle: {
    height: "fit-content",
  },
  uploadedPreviewGridStyle: {
    maxHeight: "250px",
    position: "relative",
    p: 1,
  },
  uploadImagePreviewDeleteIconStyle: { fontSize: "20px" },
  ErrorUiGoBackStyke: { marginTop: "10px" },
  previewActivityMargin: { mb: 10 },
  finalButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    mt: 3,
  },
  RTERequiredErrorTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    mb: 0,
    mt: 2,
  },
};
export default EditSingleStyles;
