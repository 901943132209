import { Alert } from "@mui/material";
import IAlertType from "../enums/AlertEnum";

interface IAlertService {
  message: string;
  type: IAlertType;
}

const AlertService = ({ message, type }: IAlertService) => {
  return (
    <Alert variant="filled" severity={type}>
      {message}
    </Alert>
  );
};

export default AlertService;
