import React from "react";
import {
  TextField,
  Typography,
  Box,
  Grid,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Autocomplete,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { ThemeProvider } from "@emotion/react";
import ForwardIcon from "@mui/icons-material/Forward";
import AdvanceSearchStyles from "../../styles/advance-search";
import "./advanced-search.scss";
import IAdvancedSearchEnum from "../../enums/AdvancedSearchEnum";
import isEmpty from "../../utils/ObjectIsEmpty";

function AdvancedSearch({
  open,
  onClose,
  form,
  formChangeHandler,
  includeState,
  setIncludeState,
  handleClearFilters,
  handleSearch,
  selectedGrades,
  setSelectedGrades,
  thinkificUserInfo,
  activitiesSearchCount,
}: AdvancedSearchProps) {
  const handleTypeChange = async (
    _: React.SyntheticEvent<Element, Event>,
    value: BaseResponse | { value: string } | null,
  ) => {
    if (value && "value" in value && value.value === "Select") {
      formChangeHandler(IAdvancedSearchEnum.type, null);
    } else {
      formChangeHandler(IAdvancedSearchEnum.type, value as BaseResponse | null);
    }
  };

  const handleSubTypeChange = (
    _: React.SyntheticEvent<Element, Event>,
    values: BaseResponse[] | null,
  ) => {
    formChangeHandler(IAdvancedSearchEnum.subType, values);
  };

  const handlePlayersChange = async (
    _: React.SyntheticEvent<Element, Event>,
    value: BaseResponse | { value: string } | null,
  ) => {
    if (value && "value" in value && value.value === "Select") {
      formChangeHandler(IAdvancedSearchEnum.numberOfPlayers, null);
    } else {
      formChangeHandler(
        IAdvancedSearchEnum.numberOfPlayers,
        value as BaseResponse | null,
      );
    }
  };

  const handleSpaceChange = async (
    _: React.SyntheticEvent<Element, Event>,
    value: BaseResponse | { value: string } | null,
  ) => {
    if (value && "value" in value && value.value === "Select") {
      formChangeHandler(IAdvancedSearchEnum.spaceRequirement, null);
    } else {
      formChangeHandler(
        IAdvancedSearchEnum.spaceRequirement,
        value as BaseResponse | null,
      );
    }
  };

  const handleSkillsChange = (
    _: React.SyntheticEvent<Element, Event>,
    values: BaseResponse[] | null,
  ) => {
    formChangeHandler(IAdvancedSearchEnum.skillsAndConcepts, values);
  };

  const handleEquipmentChange = (
    _: React.SyntheticEvent<Element, Event>,
    values: BaseResponse[],
  ) => {
    formChangeHandler(IAdvancedSearchEnum.equipment, values);
  };

  // const handleProgressionChange = (
  //   _: React.SyntheticEvent<Element, Event>,
  //   value: BaseResponse | null,
  // ) => {
  //   formChangeHandler(IAdvancedSearchEnum.progressionLevel, value);
  // };

  const handleIncludesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setIncludeState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    formChangeHandler(name, checked);
  };

  type StateIncludeKey = keyof typeof includeState;

  const gradeMappings: Record<string, string[]> = {
    "Pre-K": ["Pre-K"],
    "K-2": ["K", "1", "2"],
    "3-5": ["3", "4", "5"],
    "6-8": ["6", "7", "8"],
    "K-5 OST": ["K", "1", "2", "3", "4", "5"],
    "5-8 OST": ["5", "6", "7", "8"],
  };

  const uniqueGrades = Array.from(new Set(Object.values(gradeMappings).flat()));

  const handleGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    const newSelectedGrades = checked
      ? [...selectedGrades, name]
      : selectedGrades.filter((grade) => grade !== name);
    setSelectedGrades(newSelectedGrades);
    const gradesCheckedString = newSelectedGrades.join(",");
    const gradeIncludes: boolean[] = [];
    const keys: string[] = [];
    Object.keys(gradeMappings).forEach((key) => {
      const gradeInclude = newSelectedGrades.some((grade) =>
        gradeMappings[key].includes(grade),
      );
      gradeIncludes.push(gradeInclude);
      keys.push(key);
    });
    formChangeHandler(
      IAdvancedSearchEnum.gradeBands,
      gradeIncludes,
      keys,
      gradesCheckedString,
    );
  };

  const includeCheckboxes = [
    { label: "Video Demo", name: "video" },
    { label: "Virtual Adaptation", name: "virtual" },
    { label: "SEL Reinforcement", name: "sel" },
    { label: "Inclusion Guidance", name: "inclusion" },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOptions = (value: any, options: any[]) => {
    if (value !== null) {
      return [
        {
          value: "Select",
        },
        ...(options || []),
      ];
    }
    return [...(options || [])];
  };
  const getTypeOptions = () => getOptions(form.type.value, form.type.options);

  const playerOrder = ["Solo", "Pairs", "Groups"];
  const playerOptions = form.numberOfPlayers.options.sort((a, b) => {
    return playerOrder.indexOf(a.value) - playerOrder.indexOf(b.value);
  });
  const getPlayerOptions = () => {
    return getOptions(form.numberOfPlayers.value, playerOptions);
  };

  const spaceOrder = [
    "Self - Space",
    "Limited Space",
    "Standard Open Space",
    "Large Open Space",
  ];
  const spaceOptions = form.spaceRequirement.options.sort((a, b) => {
    return spaceOrder.indexOf(a.value) - spaceOrder.indexOf(b.value);
  });
  const getSpaceOptions = () =>
    getOptions(form.spaceRequirement.value, spaceOptions);

  const formatOptionLabel = (option: BaseResponse) => {
    switch (option.value) {
      case "Fitness":
        return `${option.value} (Go Fitness)`;
      case "Cooperative & Rhythm":
      case "Manipulative Skills":
        return `${option.value} (Go Activity)`;
      default:
        return option.value;
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modalScroll custom-scrollbar"
      >
        <ThemeProvider theme={AdvanceSearchStyles.customTheme}>
          <Box sx={AdvanceSearchStyles.modalContainer}>
            <CloseIcon
              onClick={onClose}
              sx={AdvanceSearchStyles.closeButtonStyle}
            />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h1"
              mb={2}
              mt={1}
              sx={AdvanceSearchStyles.modalHeader}
            >
              <img
                src="/assets/icons/binoculars-blue.svg"
                alt="search's Logo"
                className="searchLogo"
              />
              <span className="searchHeader fontHeading">
                Activity Explorer
              </span>
            </Typography>
            <Grid
              container
              spacing={2}
              className="modal-grid"
              sx={AdvanceSearchStyles.modalGridContainer}
            >
              <Grid item xs={12} md={12}>
                <TextField
                  id="outlined-size-small"
                  className="customHeight"
                  fullWidth
                  sx={AdvanceSearchStyles.textFieldContainer}
                  focused
                  onChange={(e) =>
                    formChangeHandler(e.target.name, e.target.value)
                  }
                  autoComplete="off"
                  value={form.name.value}
                  name={form.name.name}
                  label={form.name.label}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {form.type && (
                  <Autocomplete
                    id="size-small-outlined"
                    className="customHeight custom-autocomplete"
                    value={form.type.value}
                    onChange={handleTypeChange}
                    options={getTypeOptions()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        placeholder="Select"
                        focused
                      />
                    )}
                    getOptionLabel={(option) => formatOptionLabel(option)}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          display:
                            option.value === "Cooperative & Rhythm" ||
                            option.value === "Manipulative Skills"
                              ? "block"
                              : "flex",
                        }}
                      >
                        <span>{option.value}</span>
                        {option.value === "Fitness" && (
                          <span style={{ color: "#c3c2c9" }}>
                            &nbsp;(Go Fitness)
                          </span>
                        )}
                        {(option.value === "Cooperative & Rhythm" ||
                          option.value === "Manipulative Skills") && (
                          <span
                            style={{
                              color: "#c3c2c9",
                            }}
                          >
                            &nbsp;(Go Activity)
                          </span>
                        )}
                      </li>
                    )}
                    clearIcon={null}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} sx={AdvanceSearchStyles.gridSpace}>
                <Autocomplete
                  multiple
                  id="multiple-subtype"
                  className="custom-autocomplete"
                  value={form.subType.value}
                  onChange={handleSubTypeChange}
                  options={form.subType.options || []}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Subtype(s)"
                      placeholder="Select"
                      focused
                    />
                  )}
                  ChipProps={{
                    className: "autocomplete-chip",
                    variant: "filled",
                    deleteIcon: (
                      <CancelRoundedIcon className="autocomplete-delete-icon" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} mb={1}>
                <Autocomplete
                  multiple
                  id="skillsAndConcepts-autocomplete"
                  className="custom-autocomplete"
                  options={form.skillsAndConcepts.options || []}
                  value={form.skillsAndConcepts.value}
                  onChange={handleSkillsChange}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Skills & Concepts"
                      placeholder="Select"
                      focused
                    />
                  )}
                  ChipProps={{
                    className: "autocomplete-chip",
                    variant: "filled",
                    deleteIcon: (
                      <CancelRoundedIcon className="autocomplete-delete-icon" />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl>
                  <FormLabel sx={AdvanceSearchStyles.checkboxLabel}>
                    Grades
                  </FormLabel>
                  <FormGroup row sx={AdvanceSearchStyles.checkboxgridstyle}>
                    {uniqueGrades.map((grade) => (
                      <FormControlLabel
                        key={grade}
                        control={
                          <Checkbox
                            sx={AdvanceSearchStyles.checkboxstyle}
                            onChange={handleGradeChange}
                            name={grade}
                            checked={
                              !!selectedGrades.find((item) => item === grade)
                            }
                          />
                        }
                        label={grade}
                        classes={{
                          label: "checkbox-label",
                          root: "checkbox-root",
                        }}
                        labelPlacement="end"
                        sx={AdvanceSearchStyles.gradeLabelStyle}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="player-autocomplete"
                  className="customHeight custom-autocomplete"
                  value={form.numberOfPlayers.value}
                  onChange={handlePlayersChange}
                  options={getPlayerOptions()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="# of Players"
                      placeholder="Select"
                      focused
                    />
                  )}
                  getOptionLabel={(option) => option.value}
                  clearIcon={null}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="size-small-outlined"
                  className="customHeight custom-autocomplete"
                  value={form.spaceRequirement.value}
                  onChange={handleSpaceChange}
                  options={getSpaceOptions()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Space"
                      placeholder="Select"
                      focused
                    />
                  )}
                  getOptionLabel={(option) => option.value}
                  clearIcon={null}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple
                  id="equipment-autocomplete"
                  className="customHeight custom-autocomplete"
                  options={form.equipment.options || []}
                  value={form.equipment.value}
                  onChange={handleEquipmentChange}
                  getOptionLabel={(option) => option.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Equipment"
                      placeholder="Select"
                      focused
                    />
                  )}
                  ChipProps={{
                    className: "autocomplete-chip",
                    variant: "filled",
                    deleteIcon: (
                      <CancelRoundedIcon className="autocomplete-delete-icon" />
                    ),
                  }}
                />
              </Grid>

              {/* <Grid xs={6}>
                <Autocomplete
                  id="size-small-outlined"
                  className="customHeight"
                  value={form.progressionLevel.value}
                  onChange={handleProgressionChange}
                  options={form.progressionLevel.options}
                  renderInput={(params) => (
                    <div>
                      <img
                        alt=""
                        src="/assets/icons/info.svg"
                        className="iconStyle"
                      />
                      <TextField
                        {...params}
                        label="Progression"
                        placeholder="Select"
                        focused
                      />
                    </div>
                  )}
                  getOptionLabel={(option) => option.value}
                  clearIcon={null}
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <TextField
                  label="National SHAPE PE Standard (2013)"
                  id="outlined-size-small"
                  className="customHeight"
                  fullWidth
                  sx={AdvanceSearchStyles.textFieldContainer}
                  focused
                  value={form.shapePEStandards.value}
                  onChange={(e) =>
                    formChangeHandler(e.target.name, e.target.value)
                  }
                  name={form.shapePEStandards.name}
                  autoComplete="off"
                  placeholder='e.g. "S1" or "S1.E22.3"'
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel sx={AdvanceSearchStyles.checkboxLabel}>
                    Includes:
                  </FormLabel>
                  <FormGroup sx={AdvanceSearchStyles.checkboxgridstyle}>
                    {includeCheckboxes.map(({ label, name }) => (
                      <FormControlLabel
                        key={name}
                        control={
                          <Checkbox
                            sx={AdvanceSearchStyles.checkboxstyle}
                            checked={includeState[name as StateIncludeKey]}
                            onChange={handleIncludesChange}
                            name={name}
                          />
                        }
                        label={label}
                        classes={{
                          label: "checkbox-label",
                          root: "checkbox-root",
                        }}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                mt={2}
                sx={AdvanceSearchStyles.gridSpace}
              >
                <FormControl>
                  <FormLabel sx={AdvanceSearchStyles.checkboxLabel}>
                    Others:
                  </FormLabel>{" "}
                  <FormControlLabel
                    sx={AdvanceSearchStyles.equipmentStyle}
                    control={
                      <Checkbox
                        checked={form.isEquipmentRequired.value}
                        onChange={(e) =>
                          formChangeHandler(
                            IAdvancedSearchEnum.isEquipmentRequired,
                            e.target.checked,
                          )
                        }
                        className="customHeight"
                        sx={AdvanceSearchStyles.equipmentCheckbox}
                      />
                    }
                    label="No Equipment Required"
                    labelPlacement="end"
                  />
                  {!isEmpty(thinkificUserInfo) && (
                    <FormControlLabel
                      sx={AdvanceSearchStyles.savedActivityStyle}
                      control={
                        <Checkbox
                          checked={form.isSearchInSavedActivities.value}
                          onChange={(e) =>
                            formChangeHandler(
                              IAdvancedSearchEnum.isSearchInSavedActivities,
                              e.target.checked,
                            )
                          }
                          className="customHeight"
                          sx={AdvanceSearchStyles.equipmentCheckbox}
                        />
                      }
                      label="My Saved Activities"
                      labelPlacement="end"
                    />
                  )}
                </FormControl>
              </Grid>
              {activitiesSearchCount.isDisplay && (
                <Grid
                  style={{ width: "100%", color: "#1976d2", fontWeight: "550" }}
                >
                  <div style={{ textAlign: "center", marginTop: "30px" }}>
                    Maximum Activity Results:{" "}
                    <span
                      style={{
                        color:
                          activitiesSearchCount.count === 0
                            ? "#cc302f"
                            : "#5e9820",
                      }}
                    >
                      {activitiesSearchCount.count}
                    </span>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "13px",
                      marginTop: "5px",
                      fontWeight: "500",
                    }}
                  >
                    {activitiesSearchCount.count === 0
                      ? "(Please make your search less specific.)"
                      : "(Does not account for subscription access)"}
                  </div>
                </Grid>
              )}
              <Grid item xs={12} mt={2} sx={AdvanceSearchStyles.searchBtnAlign}>
                <Button
                  variant="outlined"
                  className="customClearButton"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>

                <button
                  type="button"
                  className="customSearchButton"
                  onClick={handleSearch}
                >
                  Search
                  <span>
                    <ForwardIcon className="search-arrow" />
                  </span>
                </button>
              </Grid>
            </Grid>
          </Box>
        </ThemeProvider>
      </Modal>
    </div>
  );
}

export default AdvancedSearch;
