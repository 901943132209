import { Box, Button, Paper, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import EditSingleStyles from "../../../styles/editSingleActivity";

interface ErrorUIProps {
  title: string | ReactNode;
  description: string;
  renderButton?: ReactNode;
}

export default function ErrorUI({
  title,
  description,
  renderButton,
}: ErrorUIProps) {
  return (
    <Box className="autoStyle" sx={EditSingleStyles.boxContainer}>
      <Paper className="error-ui-styles">
        <Typography variant="h5" sx={EditSingleStyles.typoLabel}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={EditSingleStyles.typoLabel}
          color="error"
        >
          {description ?? ""}
        </Typography>
        {!renderButton ? (
          <Link to="/">
            <Button variant="contained" className="centerAlign">
              Go Back
            </Button>
          </Link>
        ) : (
          renderButton
        )}
      </Paper>
    </Box>
  );
}

ErrorUI.defaultProps = {
  renderButton: null,
};
