const activitiesTableStyles = {
  container: {
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "15px",
  },
  searchField: {
    marginRight: "10px",
    background: "white",
  },
  headerSearch: {
    marginRight: "10px",
  },
  headerButton: {
    backgroundColor: "#0063ab",
    color: "#FFFFFF",
    fontFamily: "Montserrat",
  },
  tablePaper: {
    width: "calc(100% - 20px)",
    mb: 2,
    margin: "10px",
    padding: "10px",
  },
  table: {
    minWidth: 750,
  },
  tableRow: {
    cursor: "pointer",
  },
  fontMontserrat: {
    fontFamily: "Montserrat",
  },
  editSVGIcon: {
    marginRight: "12px",
  },
  tablePagination: {
    fontFamily: "Montserrat",
    "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select, & .MuiTablePagination-actions":
      {
        fontFamily: "Montserrat",
      },
  },
};

export default activitiesTableStyles;
