import { Button, Container, Grid } from "@mui/material";
import {
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./view-single-activity.scss";
// import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import { saveActivityForUser } from "../../../APIs/activitiesApi";
import {
  fetchActivityData,
  fetchUserActivityByDocId,
  fetchUserActivityById,
} from "../../../APIs/viewSingleActivityApi";
import AlertContext from "../../../context/alertContext";
import LoaderContext from "../../../context/loaderContext";
import SearchContext from "../../../context/searchContext";
import ThinkificUserContext from "../../../context/thinkificUserContext";
import ILocalStorageEnum from "../../../enums/LocalStorageEnum";
import ErrorUI from "../create-edit-single-activity/error-ui";

const MainActivityContainer = lazy(() => import("./main-activity-container"));

interface IViewSingleActivity {
  activityId: string | null;
  setActivityId: Dispatch<SetStateAction<string | null>>;
}

function ViewSingleActivity({
  activityId,
  setActivityId,
}: IViewSingleActivity) {
  const { errorAlert } = useContext<IAlertContext>(AlertContext);
  const { setIsLoader } = useContext<ILoader>(LoaderContext);
  const { thinkificUserInfo, setThinkificUserInfo } =
    useContext(ThinkificUserContext);
  const { searchFilterDetails } =
    useContext<ISearchParamsContext>(SearchContext);

  const location = useLocation();

  const [activityData, setActivityData] = useState<IActivity | null>(null);
  const [isActivityExists, setIsActivityExists] = useState(true);

  const id = activityId;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      try {
        if (!id) {
          setActivityData(null);
          return;
        }
        if (window.self === window.top) {
          const data = await fetchActivityData(id);
          setActivityData(data);
        } else if (thinkificUserInfo.activityId) {
          const data = await fetchUserActivityByDocId(
            thinkificUserInfo?.id?.toString(),
            thinkificUserInfo.activityId,
            thinkificUserInfo,
          );
          setActivityData(data);
        } else {
          const data = await fetchUserActivityById(
            thinkificUserInfo?.id?.toString(),
            id,
            thinkificUserInfo,
          );
          setActivityData(data);
        }
        setIsActivityExists(true);
      } catch (error) {
        errorAlert("Error fetching activity data");
        setIsActivityExists(false);
      }
      setIsLoader(false);
    };
    fetchData();
  }, [id, errorAlert, setIsLoader, thinkificUserInfo]);

  useEffect(() => {
    const links = document.querySelectorAll(".accordion-section a");
    links.forEach((link) => {
      link.setAttribute("target", "_blank");
    });
  }, [activityData]);

  useEffect(() => {
    if (window.self !== window.top) {
      const thinkificUserDetails = localStorage.getItem(
        ILocalStorageEnum.THINKIFIC_USER_DETAILS,
      );
      if (!thinkificUserDetails) {
        navigate("/login");
      }
    }
  }, [navigate]);

  function findPreviousData(array: string[], currentString: string) {
    const currentIndex = array.indexOf(currentString);
    if (currentIndex === -1 || currentIndex === 0) {
      return null;
    }
    return array[currentIndex - 1];
  }

  function findNextString(array: string[], currentString: string) {
    const currentIndex = array.indexOf(currentString);
    if (currentIndex === -1 || currentIndex === array.length - 1) {
      return null;
    }
    return array[currentIndex + 1];
  }

  const saveActivity = async (isSave: boolean, activityGuidId: string) => {
    setIsLoader(true);

    const response = await saveActivityForUser(
      errorAlert,
      thinkificUserInfo?.id || 0,
      activityGuidId,
      isSave,
    );

    if (response) {
      setActivityData({
        ...(activityData || ({} as IActivity)),
        isSavedActivity: !activityData?.isSavedActivity,
      });
    }

    setIsLoader(false);
  };
  const handleReturnToListClick = () => {
    setActivityId(null);
  };

  return (
    <section>
      <Container>
        <Grid container spacing={2} className="hide-on-print">
          <Grid item xs={12} md={6} sm={5}>
            <Link
              className="btnLink back-arrow oswald"
              to={location.pathname}
              onClick={() => {
                setActivityId(null);
                if (window.self !== window.top) {
                  const thinkificUserData = { ...thinkificUserInfo };
                  thinkificUserData.activityId = "";
                  setThinkificUserInfo({ ...thinkificUserData });
                }
              }}
            >
              <img
                src="/assets/icons/back-arrow.svg"
                alt="back-arrow"
                className="backArrow"
                style={{ paddingRight: "0.5rem" }}
              />
              Back to Search Results
            </Link>
          </Grid>
          <Grid item xs={12} md={3} sm={3.5}>
            {findPreviousData(searchFilterDetails.activityList, id || "") && (
              <Link
                className="btnLink oswald"
                to={location.pathname}
                onClick={() => {
                  setActivityId(
                    findPreviousData(
                      searchFilterDetails.activityList,
                      id || "",
                    ),
                  );
                }}
              >
                <i className="fa-solid fa-arrow-left" />
                Previous Activity
              </Link>
            )}
          </Grid>
          <Grid item xs={12} md={3} sm={3.5}>
            {findNextString(searchFilterDetails.activityList, id || "") && (
              <Link
                className="btnLink justEnd oswald"
                to={location.pathname}
                onClick={() => {
                  setActivityId(
                    findNextString(searchFilterDetails.activityList, id || ""),
                  );
                }}
              >
                Next Activity <i className="fa-solid fa-arrow-right" />
              </Link>
            )}
          </Grid>
        </Grid>
        <Suspense>
          {activityData && (
            <MainActivityContainer
              activityData={activityData}
              saveActivity={saveActivity}
              isPreview={false}
            />
          )}
        </Suspense>
        {!isActivityExists && (
          <ErrorUI
            title="Something went wrong"
            description="It seems there's an issue. Please check the other activities or return to the activity list."
            renderButton={
              <Button variant="contained" onClick={handleReturnToListClick}>
                Return To activities
              </Button>
            }
          />
        )}
      </Container>
    </section>
  );
}

export default ViewSingleActivity;
